import {
    Analysis,
    BookPathSlugs,
    BookSheetSlugs,
    BookSlugs,
    CodeArticle,
    CollectionName,
    Keyword,
    LawType,
    ModelType,
    PublicationSheet,
    SearchableRecordInstantSearch,
} from '@livv/models';
import { Hit } from 'instantsearch.js/es/types';

export const getArticleLink = (id: string): string => `/articles/${encodeURIComponent(id)}`;

export const getBookLink = ({ bookId }: BookSlugs): string =>
    `/books/${encodeURIComponent(bookId)}`;

export const getDecisionLink = (decisionId: string, analysisId?: string): string =>
    `/decisions/${encodeURIComponent(decisionId)}${
        analysisId ? `?analysisId=${encodeURIComponent(analysisId)}` : ''
    }`;

export const getAnalysisLink = (analysisId: string, decisionId: string): string =>
    getDecisionLink(decisionId, analysisId);

export const getGlossaryTermLink = (glossaryTermId: string): string =>
    `/glossary/${encodeURIComponent(glossaryTermId)}`;

export const getLawLink = (lawId: string): string => `/laws/${encodeURIComponent(lawId)}`;

export const getNodeLink = ({ bookId, bookPath }: BookPathSlugs): string =>
    `${getBookLink({ bookId })}/${encodeURIComponent(bookPath)}`;

export const getSheetLink = ({ sheetId, bookId, bookPath }: BookSheetSlugs): string =>
    `${getNodeLink({ bookId, bookPath })}/${encodeURIComponent(sheetId)}`;

export const getStatisticLink = (id: string): string => `/statistics/${encodeURIComponent(id)}`;

export const completePreviewLink = (baseHref: string, appUrl: string, query?: string): string => {
    const url = new URL(`https://${appUrl}${baseHref}`);
    if (query) {
        url.searchParams.append('search', query);
    }

    return `${url.pathname}${url.search}`;
};

export const getThematicPlanLink = (
    theme: string,
    indexName: string,
    keyword?: Keyword,
    modelType?: ModelType.DECISION | ModelType.LAW_TEXT,
    scrollToResults = false,
) =>
    keyword
        ? `/search/icicle/${[theme, keyword.treeId, ...keyword.path].join('%2F')}${
              modelType
                  ? `?${indexName}[menu][modelType]=${modelType}${
                        scrollToResults ? '#results' : ''
                    }`
                  : ''
          }`
        : '/search/icicle';

type GetDocumentUrl =
    | ((docId: string, bookDocument: CodeArticle | PublicationSheet) => string)
    | ((docId: string, analysis: Analysis) => string)
    | ((docId: string) => string);

type GetDocumentUrlByCollectionName = (
    collectionName: CollectionName | string,
) => GetDocumentUrl | undefined;

/**
 * Wrapper util for building urls
 * @param collectionName collection in which the document is stored
 * @returns getDocumentUrl : a function taking the documentId and eventually its data (CodeArticles, PublicationSheets) to build its url
 */
export const getDocumentUrlByCollectionName: GetDocumentUrlByCollectionName = (collectionName) => {
    switch (collectionName) {
        case CollectionName.ANALYSES:
            return (analysisId: string, { decisionId }: Analysis) =>
                getAnalysisLink(analysisId, decisionId);
        case CollectionName.ARTICLES:
            return getArticleLink;
        case CollectionName.DECISIONS:
            return (decisionId: string) => getDecisionLink(decisionId);
        case CollectionName.CODE_ARTICLES:
        case CollectionName.PUBLICATION_SHEETS:
            return (docId: string, { keyword }: CodeArticle | PublicationSheet) =>
                getSheetLink({
                    bookId: keyword.treeId,
                    bookPath: keyword.path.join('/'),
                    sheetId: docId,
                });
        case CollectionName.GLOSSARY_TERMS:
            return getGlossaryTermLink;
        case CollectionName.LAW_TEXTS:
            return getLawLink;
        case CollectionName.STATISTICS:
            return getStatisticLink;
        default:
            return undefined;
    }
};

export const getHref = ({ baseObjectID, ...hit }: Hit<SearchableRecordInstantSearch>): string => {
    switch (hit.modelType) {
        case ModelType.ANALYSIS: {
            const { decisionId } = hit;

            return getDecisionLink(decisionId, baseObjectID);
        }
        case ModelType.ARTICLE:
            return getArticleLink(baseObjectID);
        case ModelType.DECISION:
            return getDecisionLink(baseObjectID);
        case ModelType.GLOSSARY_TERM: {
            const { redirectionId } = hit;

            return getGlossaryTermLink(redirectionId || baseObjectID);
        }
        case ModelType.LAW_TEXT: {
            const { path, treeId } = hit;
            const isCodeArticle = baseObjectID.startsWith(LawType.LEGIARTI);

            return isCodeArticle
                ? getSheetLink({ bookId: treeId, bookPath: path.join('/'), sheetId: baseObjectID })
                : getLawLink(baseObjectID);
        }
        case ModelType.PUBLICATION_SHEET: {
            const { path, treeId } = hit;

            return getSheetLink({
                bookId: treeId,
                bookPath: path.join('/'),
                sheetId: baseObjectID,
            });
        }
        case ModelType.STATISTIC:
            return getStatisticLink(baseObjectID);
        default:
            return '';
    }
};
